<template>
  <v-navigation-drawer width="100%" style="height: 93vh">
    <v-toolbar dense flat class="mt-2 mb-0">
      <v-toolbar-title>Actividad</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-row no-gutters dense class="ma-2" align="center">
        <v-btn text icon @click="dateBack">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="slide-y-transition"
          offset-y
          min-width="290px">
        <template v-slot:activator="{ on }">
            <v-col sm="6">
            <v-text-field
              v-model="date"
              label="Elige la fecha"
              prepend-icon="mdi-calendar-range"
              readonly
              v-on="on">
            </v-text-field>
          </v-col>
        </template>
            <v-date-picker
              class="mt-1"
              ref="picker"
              v-model="pickerDate"
              :picker-date.sync="pickerDate"
              :max="todayDate"
              show-current="true"
              @input="dateMenu = false"
              locale="es">
            </v-date-picker>
          </v-menu>
          <v-btn :disabled="isAtLastDate" text icon @click="dateNext">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-col sm="1">
            <v-btn class="pl-0" text @click="today">Hoy</v-btn>
          </v-col>
    </v-row>
    <v-skeleton-loader
      :loading="getTimelinesIsLoading"
      :transition="transition"
      height="94"
      type="list-item-avatar-two-line"
    >
      <v-card flat class="mx-auto scroll">
        <v-divider></v-divider>
        <v-list two-line dense subheader>
          <v-subheader>{{getTimelinesTimelines.length}} USUARIOS</v-subheader>
          <v-list-item-group>
            <template v-for="(timeline, index) in getTimelinesTimelines">
              <v-list-item :key="timeline.id" @click="timelineClicked(timeline)">
                <template v-slot:default="{ active, toggle }">
                  <v-list-item-avatar>
                    <v-avatar color="grey">
                      <span class="white--text headline">{{getInitials(timeline.displayName)}}</span>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="timeline.displayName"></v-list-item-title>
                  </v-list-item-content>
                  {{ active ? '' : ''}}
                  {{ toggle ? '' : ''}}
                </template>
              </v-list-item>
              <v-divider v-if="index + 1 < getTimelinesTimelines.length" :key="index"></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-skeleton-loader>
  </v-navigation-drawer>
</template>
<script>
import { to, addDays, format } from '@/helpers'
import { TIMELINES_GET_TIMELINES, TIMELINES_GET_TIMELINE } from '@/store/actions.type'
import { TIMELINES_SET_SELECTED_DATE } from '@/store/mutations.type'
import { mapGetters } from 'vuex'
import { parseISO } from 'date-fns'
import { dateFromNow } from '@/mixins'
import { es } from 'date-fns/esm/locale'
export default {
  name: 'STimelinesSidebar',
  props: {},
  mounted() {
    this.getTimelines()
    if (this.getTimelinesSelectedDate) {
      this.pickerDate = this.getTimelinesSelectedDate.toISOString().substr(0, 10)
    }
  },
  data() {
    return {
      dateMenu: false,
      todayDate: new Date().toISOString().substr(0, 10),
      pickerDate: new Date().toISOString().substr(0, 10),
      selectedStatus: null,
      transition: 'fade-transition'
    }
  },
  watch: {
    pickerDate(date) {
      date = parseISO(date)
      this.updateDate(date)
    }
  },
  computed: {
    date() {
      return format(this.getTimelinesSelectedDate, 'dd MMM yyyy', {
        locale: es
      })
    },
    isAtLastDate() {
      const tomorrow = addDays(this.getTimelinesSelectedDate, 1)
      return tomorrow.getTime() > new Date().getTime()
    },
    ...mapGetters([
      'getTimelinesTimelines',
      'getTimelinesIsLoading',
      'getTimelinesSelectedDate'
    ])
  },
  methods: {
    dateBack() {
      const newDate = addDays(this.getTimelinesSelectedDate, -1)
      this.updateDate(newDate)
      this.getTimelines()
    },
    dateNext() {
      const newDate = addDays(this.getTimelinesSelectedDate, 1)
      this.updateDate(newDate)
      this.getTimelines()
    },
    today() {
      const newDate = new Date()
      this.updateDate(newDate)
      this.getTimelines()
    },
    updateDate(date) {
      this.$store.commit(TIMELINES_SET_SELECTED_DATE, date)
      this.pickerDate = date.toISOString().substr(0, 10)
      this.getTimelines()
    },
    async getTimelines() {
      const [err] = await to(this.$store.dispatch(TIMELINES_GET_TIMELINES))
      if (err) {
        this.error = err
        this.isLoading = false
      }
      this.isLoading = false
    },
    timelineClicked(timeline) {
      this.getTimeline(timeline.id)
    },
    getInitials(displayName) {
      if (!displayName) return ''
      return displayName.charAt(0)
    },
    async getTimeline(userId) {
      const [err] = await to(this.$store.dispatch(TIMELINES_GET_TIMELINE, userId))
      if (err) {
        this.error = err
        this.isLoading = false
      }
      this.isLoading = false
    }
  },
  components: {},
  mixins: [dateFromNow]
}
</script>
