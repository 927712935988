<template>
  <GmapMap
    ref="gmap"
    v-show="!getTimelinesIsLoading"
    :center="center"
    :zoom="zoom"
    :options="options"
    style="width: 100%; height: 100%">
        <gmap-custom-marker v-for="(marker, index) in markers" v-bind:key="marker.id" :marker="marker">
          <img v-if="index === 0" width="48px" height="48px" src="/img/icons/pin01.png"/>
          <img v-if="index === 1" width="48px" height="48px" src="/img/icons/pin02.png"/>
        </gmap-custom-marker>

        <gmap-polyline
          :path="getTimelinesTimeline.events"
          :editable="false"
          ref="polyline">
        </gmap-polyline>
  </GmapMap>
</template>
<script>
// import GoogleMapCluster from 'vue2-google-maps/dist/components/cluster'
import GmapCustomMarker from 'vue2-gmap-custom-marker'
import { gmapApi } from 'vue2-google-maps'
import { mapGetters } from 'vuex'
export default {
  name: 'STimelinesMap',
  props: {},
  data() {
    return {
      zoom: 13,
      options: {
        clickableIcons: false,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false
      },
      center: {
        lat: 19.4101,
        lng: -99.1699
      },
      markers: []
    }
  },
  computed: {
    google: gmapApi,
    ...mapGetters(['getTimelinesTimelines', 'getTimelinesIsLoading', 'getTimelinesTimeline'])
  },
  methods: {},
  watch: {
    getTimelinesTimeline: function() {
      this.$refs.gmap.$mapPromise.then(map => {
        const bounds = new this.google.maps.LatLngBounds()
        for (const timeline of this.getTimelinesTimeline.events) {
          bounds.extend({
            lat: timeline.latitude,
            lng: timeline.longitude
          })
        }
        map.fitBounds(bounds)
        this.center = bounds.getCenter()

        this.markers = []
        const length = this.getTimelinesTimeline.events.length
        this.markers.push(this.getTimelinesTimeline.events[0])
        this.markers.push(this.getTimelinesTimeline.events[length - 1])
      })
    }
  },
  components: {
    'gmap-custom-marker': GmapCustomMarker
  }
}
</script>
