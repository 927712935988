<template>
  <v-row no-gutters>
    <v-col cols="3" md="3" xl="2">
      <s-timelines-sidebar></s-timelines-sidebar>
    </v-col>
    <v-col>
      <s-timelines-map></s-timelines-map>
    </v-col>
    <v-col cols="3" md="3" xl="2" v-if="getTimelinesTimeline.id">
      <s-timeline-user-sidebar></s-timeline-user-sidebar>
    </v-col>
  </v-row>
</template>
<script>
import STimelinesMap from '@/components/app/timelines/s-timelines-map'
import STimelinesSidebar from '@/components/app/timelines/s-timelines-sidebar'
import STimelineUserSidebar from '@/components/app/timelines/s-timeline-user-sidebar'
import { mapGetters, mapActions } from 'vuex'
import { SETTINGS_HIDE_SIDEBAR } from '@/store/actions.type'

export default {
  name: 'Timelines',
  props: {},
  data() {
    return {
      isLoading: true,
      error: null
    }
  },
  computed: {
    ...mapGetters(['getTimelinesTimeline'])
  },
  mounted() {
    this.hideSideBar()
  },
  methods: {
    ...mapActions({
      hideSideBar: SETTINGS_HIDE_SIDEBAR
    })
  },
  components: { STimelinesSidebar, STimelinesMap, STimelineUserSidebar }
}
</script>
