<template>
  <v-navigation-drawer width="100%" style="height: 100%">
    <v-toolbar flat>
      <v-toolbar-title>{{getTimelinesTimeline.displayName}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="onCloseClicked">
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </v-toolbar>
    <v-skeleton-loader
      :loading="getTimelinesIsLoading"
      :transition="transition"
      height="400px"
      type="list-item-avatar-two-line"
    >
      <v-card flat class="mx-auto flex-column scroll">
        <v-card-text>
          <v-row class="mx-0 mb-4 flex-column">
            <div class="mb-2 dark-grey--text">Inicio Actividad</div>
            <div class="black--text">{{getTimelinesTimeline.startDate | date}}</div>
          </v-row>
          <v-row class="mx-0 mb-4 flex-column">
            <div class="mb-2 dark-grey--text">Fin Actividad</div>
            <div class="black--text">{{getTimelinesTimeline.endDate | date}}</div>
          </v-row>
          <v-row class="mx-0 mb-4 flex-column">
            <div class="mb-2 dark-grey--text">Correo electrónico</div>
            <div class="black--text">{{getTimelinesTimeline.email}}</div>
          </v-row>
          <v-row class="mx-0 mb-4 flex-column">
            <div class="mb-2 dark-grey--text">Celular</div>
            <div class="black--text">{{getTimelinesTimeline.mobilePhone}}</div>
          </v-row>
          <v-row class="mx-0 mb-4 flex-column">
            <div class="mb-2 dark-grey--text">Grupos</div>
            <div
              class="black--text mb-2"
              v-for="(group, index) in getTimelinesTimeline.groups"
              :key="index"
            >
            {{group.name}}
            </div>
          </v-row>
        </v-card-text>
      </v-card>
    </v-skeleton-loader>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters } from 'vuex'
import { dateFromNow } from '@/mixins'
import { TIMELINES_REMOVE_TIMELINE } from '@/store/mutations.type'
export default {
  name: 'STimelineUserSidebar',
  props: {},
  data() {
    return {
      dialog: false,
      img: null,
      transition: 'fade-transition'
    }
  },
  computed: {
    ...mapGetters(['getTimelinesIsLoading', 'getTimelinesTimeline'])
  },
  components: {},
  methods: {
    onMagnify(field) {
      this.dialog = true
      this.img = field.value
    },
    back() {
      this.$router.push({ name: 'timelines' })
    },
    onCloseClicked() {
      this.$store.commit(TIMELINES_REMOVE_TIMELINE)
    }
  },
  mixins: [dateFromNow]
}
</script>
